@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  font-family: 'YourCustomFont' !important;
  font-size: 20px !important;
}

body {
 
 background-color: #020084 !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/Mikhaloo.ttf') format('truetype');
  /* You can also specify font-weight, font-style, etc. */
}
@font-face {
  font-family: 'YourCustomFonts';
  src: url('./Fonts/typo.otf') format('truetype');
  /* You can also specify font-weight, font-style, etc. */
}
.desk{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  
background-image: url('./wint.png');
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
 
}
.desks{
  background-attachment: fixed;
  
background-image: url('./poke.svg');
  background-repeat: no-repeat!important;
  background-size: cover !important;
}
.mob{
  cursor: url(chand.png) !important;
  background-attachment: fixed;

  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-position: center;

  /* Set the background color with opacity and color using a CSS variable */

}

.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */
.colorofnav{
  color: #ffffff;
  
}
.colorofnavx{
  color: #ffffff;
  -webkit-text-stroke: 2px rgb(0, 0, 0); /* Border around text */
  text-stroke: 2px rgb(0, 0, 0); /* Border around text */
  font-size: 17px !important;
  
}
.colorofnavs{
  color: #ffc400;
  font-size: 40px !important;
}
.colorofnavsx{
  color: #ffc400;
  font-size: 30px !important;
}
.backall{
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
  inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
  inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
background-color: #0300bc;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0px 0px 40px 40px ;
  color: #ffffff !important;
  width: 100%;
  height: 110px
}
.backallx{
  z-index: 1000;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
  inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
  inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
background-image: linear-gradient(
  160deg,
  #0731a5,
  #112cb4,
  #4951f8,
  #1b2ebc,
  #0727a5
);
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0px 0px 40px 40px ;
  color: #ffffff !important;
  width: 100%;
  height: 100px;
}

.image_anos{
  width: 80%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 100%;
  }

      }



.anos_text{
  font-size: 3rem !important;
  color: rgb(0, 0, 0);
  font-family: Courier New,Courier,monospace;
  
}


@media screen and (min-width: 1000px){
  .anos_text{
    font-size: 7rem !important;
    color: rgb(0, 0, 0);
    font-family: Courier New,Courier,monospace;
   
  }
  
}


.anos_textg{
  font-size: 1rem !important;
  color: rgb(0, 0, 0);
  font-weight: 300 !important;

  
}


@media screen and (min-width: 1000px){
  .anos_textg{
    font-size: 2.5rem !important;
    color: rgb(0, 0, 0);
    font-weight: 300 !important;

    
  }
  
  
}




.anon_p{
font-size: 13px !important;
  color: rgb(0, 0, 0);
  font-weight: 600;
 
}


@media screen and (min-width: 1000px){
  .anon_p{
    font-size: 24px !important;
   font-weight: 600;
  color: rgb(0, 0, 0);
 
  }
  
}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 55%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.matrix-text {
  color: rgb(255, 255, 255) !important;
 font-size: 19px !important;
 
}

@media screen and (min-width: 1000px){
  .matrix-text {
  color: rgb(255, 255, 255) !important;
 font-size: 35px !important;
 
}
}
.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}










.fotx{
  color: white !important
}





















@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
background-color: #ffffff;
  width: 100%;

}

.footerx {


background-color: #6b6b6b;
  width: 100%;

}


.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #bb00ff;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}




/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #5a41fea9 !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}












































































































.image_anosl{
  width: 100%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anosl{
    width: 30%;
  }

      }












      .card {
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgb(0, 0, 0);
        border-radius: 10px;
        transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
       }
       
       .shadow {
        box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
                    0 0  0 2px rgb(190, 190, 190),
                    0.3em 0.3em 1em rgba(0,0,0,0.3);
       }
       

       @media screen and (min-width: 1000px) {
                        
                                
        .card {
          width: 100%;
          height: 294px;
          background: rgb(0, 0, 0);
          border-radius: 10px;
          transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
         
         .shadow {
          box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
                      0 0  0 2px rgb(190, 190, 190),
                      0.3em 0.3em 1em rgba(0,0,0,0.3);
         }
         
            }










       .htb_h{
        font-size: 24px !important;
        font-weight: 900;
       }
       @media screen and (min-width: 1000px){
        .htb_h{
          font-size: 30px !important;
         }
      }
      
       
       .htb_hp{
        font-size: 15px !important;
        font-weight: 500;
       }
       @media screen and (min-width: 1000px){
        .htb_hp{
          font-size: 19px !important;
         }
      }








      .center_dis{
        font-size: 6px !important;
        text-align: center;
      }

      
      .center_diss{
        font-size: 10px !important;
        text-align: center;
      }







      .h1_head {
        font-size: 140px !important;
        color: #ffffff;
        -webkit-text-stroke: 2px black; /* Border around text */
        text-stroke: 2px black; /* Border around text */
        letter-spacing: 0px !important; /* Space between words */
        word-spacing: 50px !important; 
    }
    

    .h1_headm {
      font-size: 60px !important;
      color: #ffffff;
      -webkit-text-stroke: 2px rgb(0, 0, 0); /* Border around text */
      text-stroke: 2px black; /* Border around text */
      letter-spacing:10px !important; /* Space between words */
      word-spacing: 50px !important; 
  }
  



    .ca{
      font-size: 20px !important;
      color: #000000;
      font-family: "Poppins", sans-serif !important;
      
    }
    .cax{
      font-size: 12px !important;
      color: #000000;
      font-family: "Poppins", sans-serif !important;
   
    }

    .dis{
      font-size: 10px !important;
    }
    .disx{
      font-size: 70px !important;
    }
    .disxmc{
      font-size: 70px !important;
      -webkit-text-stroke: 2px black; /* Border around text */
      text-stroke: 2px black; /* Border around text */
    }
    .disxx{
      font-size: 40px !important;
      -webkit-text-stroke: 2px black; /* Border around text */
      text-stroke: 2px black; /* Border around text */
      letter-spacing: 2px !important; /* Space between words */
      word-spacing: 40px !important; 
    }
    .dist{
      font-size: 9px !important;
      font-family: 'YourCustomFonts' !important;
      color: #000000;
    }

    .socials{
      color: #ffffff;
      font-size: 50px !important;
    }














































    .golden-button {
      touch-action: manipulation;
      display: inline-block;
      outline: none;
      font-family: inherit;
      font-size: 1em;
      box-sizing: border-box;
      border: none;
      border-radius: 0.3em;
      height: 2.75em;
      line-height: 2.5em;
      text-transform: uppercase;
      padding: 0 1em;
      background-size: 150% 150%;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23),
        inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
      border: 1px solid rgba(165, 93, 7, 0.6);
      color: rgb(255, 217, 0);
      border: 1px solid #a55d07;
 background-color: #000;
      text-shadow: 0 2px 2px rgb(0, 0, 0);
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      background-size: 100% 100%;
      background-position: center;
    }
    
    .golden-button:focus,
    .golden-button:hover {
      background-size: 150% 150%;
      box-shadow: 0 10px 20px rgba(0, 125, 197, 0.773), 0 6px 6px rgba(177, 0, 0, 0.778),
        inset 0 -2px 5px 1px #feae00, inset 0 -1px 1px 3px rgb(149, 119, 0);
      border: 1px solid rgba(165, 93, 7, 0.6);
      color: rgb(255, 217, 0);
    }
    
    .golden-button:active {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
        inset 0 -2px 5px 1px #b17d10, inset 0 -1px 1px 3px rgba(250, 227, 133, 1);
    }
    

    .backofit{
      height: 70px;
  background-color: #000000;

    }

    .fotx{
      color: #ffffff;
      position: relative;
      top: 15px;
      -webkit-text-stroke: 2px black; /* Border around text */
      text-stroke: 2px black; /* Border around text */
    }

    .makeitfix{
      
    }


    .backofaccor{
      box-shadow: 0 319x 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(110, 80, 20, 0.4),
      inset 0 -2px 5px 1px rgba(139, 66, 8, 1),
      inset 0 -1px 1px 3px rgba(250, 227, 133, 1);

    border-radius: 20px !important;
    font-family: 'YourCustomFonts' !important;
    }

    .cf{
      font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
    }



    .h1_headv {
      font-size: 30px !important;
      color: #ffffff;
  
      -webkit-text-stroke: 2px black; /* Border around text */
      text-stroke: 2px black; /* Border around text */
      letter-spacing: 0px !important; /* Space between words */
      word-spacing: 50px !important; 
  }
  @media screen and (min-width: 1000px){
    .h1_headv {
      font-size: 100px !important;
      color: #ffffff;
      -webkit-text-stroke: 2px black; /* Border around text */
      text-stroke: 2px black; /* Border around text */
      letter-spacing: 0px !important; /* Space between words */
      word-spacing: 50px !important; 
  }
    
  }

  
  .font1{
    font-family: 'YourCustomFont' !important;
    color: #000000;
    -webkit-text-stroke: 2px rgb(255, 255, 255); /* Border around text */
    text-stroke: 2px rgb(255, 255, 255); /* Border around text */
    font-weight: 900;
    font-size: 30px !important;
  }

  @media screen and (min-width: 1000px){
   
    .font1{
      font-family: 'YourCustomFont' !important;
      color: #000000;
      -webkit-text-stroke: 2px rgb(255, 255, 255); /* Border around text */
      text-stroke: 2px rgb(255, 255, 255); /* Border around text */
      font-weight: 900;
      font-size: 80px !important;
    }
  
  }




  .fontms{
    font-size: 40px !important;
  }


  .divca {
    background-color: #ffffff;
    border: 3px solid #000000;
    padding: 10px;
    transform: rotate(5deg); /* Rotate by 45 degrees */
  }





































  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #616161; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #ffb700; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0018a2; 
}